import { inject, Injectable } from "@angular/core";
import { Meta, Title } from '@angular/platform-browser';
import { SeoData } from "./interfaces/seo.data";

@Injectable({
  providedIn: 'root'
})
export class SeoService {
  private readonly metaService = inject(Meta);
  private readonly titleService = inject(Title);

  public setData(data: SeoData | undefined) {
    this.setTitle(data?.title);
    this.setKeywords(data?.keywords);
    this.setDescription(data?.description);
    this.setRobots(data?.robots);
    this.setAuthor(data?.author);
  }


  public setTitle(title?: string): void {
    this.titleService.setTitle(title!);
    if(title && title?.length) {
      this.metaService.updateTag({ name: 'title', content: title });
    } else {
      this.metaService.removeTag(`name='title'`);
    }
  }

  public setKeywords(keywords?: string): void {
    if(keywords && keywords?.length) {
      this.metaService.updateTag({
        name: 'keywords',
        content: keywords,
      });
    } else {
      this.metaService.removeTag(`name='keywords'`);
    }
  }

  public setDescription(description?: string): void {
    if(description && description?.length) {
      this.metaService.updateTag({
        name: 'description',
        content: description,
      });
    } else {
      this.metaService.removeTag(`name='description'`);
    }
  }

  public setRobots(robots?: string): void {
    if(robots && robots?.length) {
      this.metaService.updateTag({
        name: 'robots',
        content: robots,
      });
    } else {
      this.metaService.removeTag(`name='robots'`);
    }
  }

  public setAuthor(author?: string): void {
    if (author && author.length) {
      this.metaService.updateTag({ name: 'author', content: author });
    } else {
      this.metaService.removeTag(`name='author'`);
    }
  }

}