<mat-card
  appearance="outlined"
  class="mb-2"
  [ngClass]="{'error': showError && !item.files.length}"
>
  <mat-card-content class="d-flex align-items-center">
    <button
      mat-flat-button
      class="p-4 me-2 d-flex" type="button"
      [ngClass]="{'bg-primary': item.files.length && !item.isLoading, 'bg-light-dark-color': !(item.files.length && !item.isLoading)}"
      (click)="fileInput.click()"
    >
     <img class="" src="assets/svg/cloud-upload.svg" alt="">

      @if (!item.isLoading) {
        <span class="d-block text-white">Importar</span>
      }
      @else {
        <div class="d-flex">
          <mat-spinner [diameter]="20" class="me-2"></mat-spinner>
          <span class="d-block text-white">Importando</span>
        </div>
      }

      <input
        class="d-none" type="file"
        #fileInput
        accept="'.png', '.PNG', '.jpg', '.JPG', '.jpeg', '.JPEG', '.pdf', '.PDF'"
        (change)="addDocument($event)"
        [multiple]="item.multiple"
      >
    </button>
    <div class="d-flex flex-column w-100">
      <div class="d-flex justify-content-between">
        <div class="description">
          {{ item.description }}
          <small *ngIf="item.required">*</small>
        </div>
        <div class="d-flex justify-content-end">
          <mat-icon class="ms-2 text-muted" *ngIf="!!item.tooltip" [matTooltip]="item.tooltip">help</mat-icon>
        </div>
      </div>
      <small class="d-flex" *ngFor="let file of item.files; let i = index">
        @if (item.canDelete) {
          <mat-icon color="warn" style="cursor: pointer;" (click)="removeDocument(i)">
            remove
          </mat-icon>
        }
        {{ file.name }}
      </small>
    </div>
  </mat-card-content>
</mat-card>
