<section class="container-fluid justify-content-center align-items-center row min-vh-100">
  <figure class="col-lg-6">
    <img class="w-100" src="assets/svg/landing-page/home/macbook.svg" alt="Imagem de um computador">
  </figure>
  <section class="col-lg-5">
    <h1 class="light-dark-color mb-4" data-cy="home-step-by-step-title">Abrir sua conta na Broadfactor <span class="second-color">é simples</span></h1>

    <section class="container-fluid">
      <div class="row">
        <div class="col-lg-6 mb-4">
          <article class="card" data-cy="home-step-by-step-first-card">
            <span class="number">1.</span>
            <div class="d-flex flex-column align-items-center text-center">
              <mat-icon class="d-block" data-cy="home-step-by-step-first-card-icon">dialpad</mat-icon>
              <span class="d-block text-muted step" data-cy="home-step-by-step-first-card-description"><b class="mobile-number">1.</b> Clique no botão <b>Crie sua conta</b></span>
            </div>
          </article>
        </div>
        <div class="col-lg-6 mb-4">
          <article class="card" data-cy="home-step-by-step-second-card">
            <span class="number">2.</span>
            <div class="d-flex flex-column align-items-center text-center">
              <mat-icon class="d-block" data-cy="home-step-by-step-second-card-icon">summarize</mat-icon>
              <span class="d-block text-muted step" data-cy="home-step-by-step-second-card-description"><b class="mobile-number">2.</b> Preencha o  <b>Formulário</b></span>
            </div>
          </article>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6 mb-4">
          <article class="card">
            <span class="number" data-cy="home-step-by-step-third-card">3.</span>
            <div class="d-flex flex-column align-items-center text-center">
              <mat-icon class="d-block" data-cy="home-step-by-step-third-card-icon">contact_phone</mat-icon>
              <span class="d-block text-muted step" data-cy="home-step-by-step-third-card-description"><b class="mobile-number">3.</b> Aguarde o contato equipe <b>Broadfactor Pay</b></span>
            </div>
          </article>
        </div>
        <div class="col-lg-6 mb-4">
          <article class="card" data-cy="home-step-by-step-fourth-card">
            <span class="number">4.</span>
            <div class="d-flex flex-column align-items-center text-center">
              <mat-icon class="d-block" data-cy="home-step-by-step-fourth-card-icon">how_to_reg</mat-icon>
              <span class="d-block text-muted step"
              data-cy="home-step-by-step-fourth-card-description"><b class="mobile-number">4.</b> Dados validados <b>Conta liberada</b></span>
            </div>
          </article>
        </div>
      </div>

    </section>
  </section>
</section>
