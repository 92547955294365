import { Component } from '@angular/core';
import { AppMaterialModule } from '../../../../shared/app-material/app-material.module';

@Component({
  selector: 'app-confirm-modal',
  standalone: true,
  imports: [AppMaterialModule],
  templateUrl: './confirm-modal.component.html',
  styleUrl: './confirm-modal.component.scss'
})
export class ConfirmModalComponent {

}
