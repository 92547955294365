import { NgFor, NgIf } from '@angular/common';
import { Component, inject, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxMaskDirective } from 'ngx-mask';

import { RegisterService } from '../../../../core/services/register.service';
import { AppMaterialModule } from '../../../../shared/app-material/app-material.module';
import { SuccessPageComponent } from '../../components/success-page/success-page.component';
import { UploadCardComponent } from '../../components/upload-card/upload-card.component';
import { IDocumentList } from '../../interfaces/IDocumentList';
import { IInitialRegister } from '../../interfaces/IInitialRegister';
import { IPartnerRegister } from '../../interfaces/IPartnerRegister';
import { RegisterDocuments } from '../../utils/register-documents';
import { MatStepper } from '@angular/material/stepper';

@Component({
    selector: 'app-register-forms',
    imports: [AppMaterialModule, UploadCardComponent, NgFor, NgIf, ReactiveFormsModule, NgxMaskDirective, SuccessPageComponent],
    templateUrl: './register-forms.component.html',
    styleUrl: './register-forms.component.scss'
})
export class RegisterFormsComponent implements OnDestroy {
  documentList: IDocumentList[] = RegisterDocuments.list();
  finished = false;
  showDocErrors = false;
  isLoading = false;
  @ViewChild('stepper') stepper!: MatStepper;
  submitClicked = false;
  source: string = '';

  private _formBuilder = inject(FormBuilder);

  contact = this._formBuilder.group({
    cnpj: ['', Validators.required],
    fullName: ['', [Validators.required]],
    mail: ['', [Validators.required, Validators.email]],
    origin: [''],
    phone: ['', [Validators.required]],
    campaign: [null],
    privacyPolicy: [false, Validators.requiredTrue],
    bacenAccepted: [false, Validators.requiredTrue],
    pepAccepted: [false, Validators.requiredTrue],
  });

  partner = this._formBuilder.group({
    cpf: ['', [Validators.required]],
    digitalCertificate: [false, [Validators.requiredTrue]],
    fullName: ['', [Validators.required]],
    email: ['', [Validators.required, Validators.email]],
    phone: ['', [Validators.required]],
    whatsapp: ['', [Validators.required]],
    confirmPartner: [false, [Validators.requiredTrue]],
  });

  constructor(
    private router: Router,
    private registerService:RegisterService,
    private snackBar:MatSnackBar,
    public dialog: MatDialog,
    private route: ActivatedRoute
  ) {
    this.source = this.route.snapshot.data['source'];
    this.setOrigin();
  }

  ngOnDestroy(): void {
    this.snackBar.dismiss();
  }

  addDocument(event: any, index: number) {
    const files = <FileList>event.target.files;
    this.documentList[index].files = [...Array.from(files)];
    this.uploadDocument(index);
  }

  removeDocument(index: number, fileIndex: number) {
    this.documentList[index].files.splice(fileIndex, 1);
  }

  onSaveInitial() {
    this.submitClicked = true;

    if(this.contact.invalid ) {
      this.snackBar.open('Por favor, verifique e preencha todos os campos necessários.', 'OK');
      return;
    }

    if(!this.contact.get('privacyPolicy')?.value){
      this.snackBar.open('Confirme que você leu e aceita a Política de Privacidade.', 'OK');
      return;
    }

    if(!this.contact.get('bacenAccepted')?.value){
      this.snackBar.open('Você deve autorizar a consulta de informações junto a birôs de crédito e ao BACEN. ', 'OK');
      return;
    }

    if(!this.contact.get('pepAccepted')?.value){
      this.snackBar.open('Por favor, confirme sua condição como Pessoa Politicamente Exposta (PEP).', 'OK');
      return;
    }

    this.isLoading = true;

    this.registerService.addInitialRegister(this.contact.value as IInitialRegister).subscribe({
      next: (id) => {
        this.registerService.setCompanyId(id);
        this.isLoading = false;
        this.stepper.next();
        this.submitClicked = false;
      },
      error: () => {
        this.isLoading = false;
        this.snackBar.open('Erro ao realizar o cadastro da empresa.', 'OK');
      }
    });

  }

  onSavePartner() {
    this.submitClicked = true;
    if(this.partner.invalid) {
      this.snackBar.open('Por favor, verifique e preencha todos os campos necessários.', 'OK');
      return;
    }

    this.isLoading = true;

    this.registerService.addPartner(this.partner.value as IPartnerRegister).subscribe({
      next: (id) => {
        this.isLoading = false;
        this.registerService.setPartnerId(id);
        this.stepper.next();
        this.submitClicked = false;
      },
      error: () => {
        this.isLoading = false;
        this.snackBar.open('Erro ao realizar o cadastro do sócio.', 'OK');
      }
    });
  }

  uploadDocument(index: number) {
    this.documentList[index].isLoading = true;
    this.registerService.addDocuments(
      this.documentList[index].files,
      this.documentList[index].type,
      this.documentList[index].isCompanyDocument
    ).subscribe({
      next: () => {
        this.documentList[index].isLoading = false;
        this.snackBar.open(
          'Documento inserido com sucesso.',
          'OK',
          { duration: 3000 }
        );
      },
      error: () => {
        this.documentList[index].isLoading = false;
        this.documentList[index].files = [];
        this.snackBar.open('Erro ao realizar o cadastro.', 'OK');
      }
    });
  }


  onDone() {
    if(!this.documentList.every(document => document.files.length)) {
      this.showDocErrors = true;
      this.snackBar.open('Por favor, insira todos os documentos necessários.', 'OK');
      return;
    }
    this.finished = true;
  }



  redirectToHome(){
    const path = (this.source === 'B2gContabilidade') ? '/b2g-contabilidade/home' : '/home';
    this.router.navigate([path]);
  }

  setOrigin(): void {
    const hostname = window.location.hostname;
    const subdomain = hostname.split('.')[0];

    if(subdomain === 'b2g') {
      this.contact.patchValue({ origin: 'SigaPregao' });
      return;
    }

    if(!!this.source) {
      this.contact.patchValue({ origin: this.source });
      return;
    }

    this.contact.patchValue({ origin: null });
  }
}
