import { Component } from '@angular/core';
import { HeaderComponent } from '../header/header.component';
import { AppMaterialModule } from '../../../../shared/app-material/app-material.module';
import { CardComponent } from '../../components/card/card.component';
import { ICard } from '../../interfaces/ICard';
import { NgFor } from '@angular/common';
import { HomeComponent } from '../../../home/home.component';

@Component({
  selector: 'app-landing-page',
  standalone: true,
  imports: [HeaderComponent, AppMaterialModule, CardComponent, NgFor, HomeComponent],
  templateUrl: './landing-page.component.html',
  styleUrl: './landing-page.component.scss'
})
export class LandingPageComponent {
  data: ICard[] = [
    {
      number: 1,
      describe: 'Clique no botão <b class="color-broad-pay">Abrir sua conta</b>'
    },
    {
      number: 2,
      describe: 'Preencha o formulário em minutos'
    },
    {
      number: 3,
      describe: 'Aguarde o contato da nossa equipe'
    }
  ]

  goToSection(sectionID: string){
    document.getElementById(sectionID)?.scrollIntoView();
  }
}
