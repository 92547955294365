<main class="min-vh-100">
  <section class="d-flex flex-column align-items-center border bg-light-dark py-4">
    <h1 class="text-center light-dark-color">Conta Digital: Soluções completas para sua gestão financeira</h1>
    <p>
      Descubra como nossa conta digital pode facilitar seu dia a dia e impulsionar seus negócios.
    </p>
  </section>

  <div class="px-4">
    <section class="row m-0">
      <div class="col-md-6">
          @for (item of solutionList; track $index) {
            @if ($index % 2 == 0) {
              <app-simple-card [data]="item"></app-simple-card>
            }
          }
      </div>
      <div class="col-md-6">
        @for (item of solutionList; track $index) {
          @if ($index % 2 != 0) {
            <app-simple-card [data]="item"></app-simple-card>
          }
        }
      </div>
    </section>
  </div>
</main>
