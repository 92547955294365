import { inject, Injectable } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { SeoService } from "../seo/seo.service";
import { filter, map } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class RouteHelper {
  private readonly router = inject(Router);
  private readonly route = inject(ActivatedRoute);
  private readonly seoService = inject(SeoService);


  constructor() {
    this.setupRouteListening();
  }


  private setupRouteListening(): void {
    this
      .router
        .events
          .pipe(
              filter(event => event instanceof NavigationEnd),
              map(() => this.route),
              map(route => {
                while(route.firstChild) {
                  route = route.firstChild;
                }
                return route;
              })
            ).subscribe((route: ActivatedRoute) => {
              const seoData = route.snapshot.data['seo'];
              this.seoService.setData(seoData);
            });
  }
}