<div class="initial container-fluid row d-flex justify-content-center align-items-center bg-light-dark">
  <div class="col-lg-6 col-md-10 col-sm-12">
    <h1 data-cy="home-main-title">
      <span class="d-block light-dark-color">Abra sua conta digital</span>
      <span class="d-block second-color">na Broadfactor Pay!</span>
    </h1>
    <p class=" my-4 fs-5" data-cy="home-main-description">
      Tenha acesso a <b>serviços financeiros e crédito sob medida</b> para atender as necessidades da sua empresa!
    </p>
    <button
      mat-flat-button
      class="bg-primary p-4"
      routerLink="/register"
      data-cy="home-main-create-account-button"
    >
      <span class="fs-5">Crie sua conta</span>
    </button>
  </div>
  <img
    class="col-md-4 col-sm-6"
    width="388"
    height="380"
    ngSrc="assets/svg/landing-page/home/main-section.svg"
    alt="Uma mulher segurando um celular exibindo o aplicativa da Broadfactor Pay"
    priority
    data-cy="home-main-img"
  >
</div>
