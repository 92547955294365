import { NgFor, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { NgxMaskDirective } from 'ngx-mask';
import { forkJoin, of } from 'rxjs';

import { RegisterService } from '../../../../core/services/register.service';
import { AppMaterialModule } from '../../../../shared/app-material/app-material.module';
import { UploadCardComponent } from '../../components/upload-card/upload-card.component';
import { IDocumentList } from '../../interfaces/IDocumentList';
import { RegisterDocuments } from '../../utils/register-documents';
import { SuccessPageComponent } from '../../components/success-page/success-page.component';
import { ConfirmModalComponent } from '../../components/confirm-modal/confirm-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-register-forms',
  standalone: true,
  imports: [AppMaterialModule, UploadCardComponent, NgFor, NgIf, ReactiveFormsModule, NgxMaskDirective, SuccessPageComponent],
  templateUrl: './register-forms.component.html',
  styleUrl: './register-forms.component.scss',
})
export class RegisterFormsComponent {
  form!: FormGroup;
  documentList: IDocumentList[] = RegisterDocuments.list();
  finished = false;
  showDocErrors = false;
  isLoading = false;

  constructor(
    private formBuilder:FormBuilder,
    private router: Router,
    private registerService:RegisterService,
    private snackBar:MatSnackBar,
    public dialog: MatDialog
  ) {
    this.form = this.formBuilder.group({
      fullName: [null, [Validators.required]],
      cpf: [null, [Validators.required]],
      cnpj: [null, [Validators.required]],
      companyName: [null, [Validators.required]],
      mail: [null, [Validators.required]],
      phone: [null, [Validators.required]],
      whatsapp: [null, [Validators.required]],
      digitalCertificate: [false],
      origin: [this.getOrigin()],
      privacyPolicy: [false],
      bacenAccepted: [false],
    });
  }

  addDocument(event: any, index: number) {
    const files = <FileList>event.target.files;
    this.documentList[index].files = [...this.documentList[index].files, ...Array.from(files)];
  }

  removeDocument(index: number, fileIndex: number) {
    this.documentList[index].files.splice(fileIndex, 1);
  }

  uploadDocuments(id:string) {
    const observables = this.documentList.map(document => {
      if (document.files.length) {
        return this.registerService.addDocuments(document.files, document.type, id);
      }
      return of(null);
    });

    forkJoin(observables).subscribe({
      next: () => {
        this.snackBar.open('Cadastro realizado com sucesso', 'OK');
        this.finished = true;
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
        this.snackBar.open('Erro ao realizar o cadastro', 'OK');
      }
    });
  }


  onSave(){
    if(!this.form.valid || !this.documentList.every(document => document.files.length)){
      this.snackBar.open('Por favor, verifique e preencha todos os campos necessários.', 'OK');
      this.showDocErrors =  true;
      return;
    }

    if(!this.form.get('privacyPolicy')?.value){
      this.snackBar.open('Confirme que você leu e aceita a Política de Privacidade.', 'OK');
      return;
    }

    if(!this.form.get('bacenAccepted')?.value){
      this.snackBar.open('Você deve autorizar a consulta de informações junto a birôs de crédito e ao BACEN. ', 'OK');
      return;
    }


    const dialogRef = this.dialog.open(ConfirmModalComponent, {width:'600px'});

    dialogRef.afterClosed().subscribe(result => {
      if(!result) return;
      this.isLoading = true;

      this.registerService.addCompleteRegister(this.form.value).subscribe({
        next: (id) => {
          this.uploadDocuments(id);
        },
        error: (error) => {
          this.isLoading = false;
          this.snackBar.open('Erro ao realizar o cadastro', 'OK');
        }
      });
    });
  }

  redirectTo(path: string){
    this.router.navigate([path]);
  }

  getOrigin() : string | null {
    const hostname = window.location.hostname;
    const subdomain = hostname.split('.')[0];

    return (subdomain === 'b2g') ? 'SigaPregao' : null;
  }
}
