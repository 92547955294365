import { Component, inject } from '@angular/core';
import { AppMaterialModule } from '../../../shared/app-material/app-material.module';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-footer',
    imports: [AppMaterialModule],
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  router = inject(Router)
  activatedRoute = inject(ActivatedRoute)

  helpCenterURL = this.getUrl('central-de-ajuda');
  privacyPolicyURL = this.getUrl('politica-de-privacidade');

  getUrl(path: string) {
    return this.router.serializeUrl(this.router.createUrlTree([path], { relativeTo: this.activatedRoute }));
  }
}
