<mat-drawer-container autosize class="h-100">
  <mat-drawer #drawer class="p-2" mode="over" position="end" class="w-50">
    <div class="text-end mb-2">
      <button mat-icon-button (click)="drawer.toggle()" class="text-right">
          <mat-icon class="mat-18">close</mat-icon>
      </button>
    </div>
    <ul class="d-flex flex-column justify-content-between align-items-center mb-0">
      <li (click)="goToSection('start')">Início </li>
      <li (click)="goToSection('about-us')">Quem somos</li>
      <li (click)="goToSection('solutions')">Soluções</li>
      <button mat-flat-button class="bg-broad" (click)="redirectTo('register')">Crie sua Conta</button>
    </ul>
  </mat-drawer>

  <div class="example-sidenav-content">
    <app-header (menu)="drawer.toggle()" (register)="redirectTo('register')"></app-header>
    <router-outlet></router-outlet>
    <app-footer></app-footer>
  </div>
</mat-drawer-container>
