
<div
  *ngIf="!!data"
  class="row bg-white border border-success rounded mb-4"
>
  <div class="col-3 bg-broad-light text-white rounded d-flex align-items-center justify-content-center h1 mb-0 p-4">
    {{ data.number }}
  </div>
  <div class="col-9 d-flex flex-horizontal align-items-center justify-content-center">
    <span class="describe" [innerHTML]="data.describe"></span>
  </div>
</div>
