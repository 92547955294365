<form
  *ngIf="!finished"
  class="container d-flex flex-column mb-5"
  [formGroup]="form"
  (ngSubmit)="onSave()"
>
  <h3 class="text-center my-4 title">Formulário de Abertura de Conta</h3>


  <section>
    <b class="d-block mb-2 h5">Sócio Responsável</b>
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Nome</mat-label>
      <input matInput formControlName="fullName">
    </mat-form-field>
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>CPF</mat-label>
      <input matInput formControlName="cpf"  mask="000.000.000-00">
    </mat-form-field>

    <mat-form-field appearance="outline" class="w-100">
      <mat-label>E-mail</mat-label>
      <input matInput formControlName="mail">
    </mat-form-field>
    <div class="row">
      <mat-form-field appearance="outline" class="col-md-6 col-sm-12">
        <mat-label>Telefone</mat-label>
        <input matInput formControlName="phone" mask="(00) 0 0000 0000">
      </mat-form-field>
      <mat-form-field appearance="outline" class="col-md-6 col-sm-12">
        <mat-label>WhatsApp</mat-label>
        <input matInput formControlName="whatsapp" mask="(00) 0 0000 0000">
      </mat-form-field>
    </div>
  </section>


  <section class="my-5">
    <b class="d-block mb-2 h5">Empresa</b>
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>CNPJ</mat-label>
      <input matInput formControlName="cnpj" mask="00.000.000/0000-00">
    </mat-form-field>
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Razão Social</mat-label>
      <input matInput formControlName="companyName">
    </mat-form-field>
    <p>
      <mat-slide-toggle formControlName="digitalCertificate">Possuem certificado digital no nome do sócio administrador</mat-slide-toggle>
    </p>
  </section>



  <section>
    <b class="d-block mb-2 h5">Documentos</b>
    <div class="alert alert-primary d-flex align-items-center justify-content-center" role="alert">
      <mat-icon class="me-2">info</mat-icon>
      <span>
        Importe os documentos de identificação e comprovantes de residência atualizados de todos os sócios ou representantes legais mencionados no contrato social.
      </span>
    </div>
    <app-upload-card
      *ngFor="let document of documentList; let i = index"
      [item]="document"
      [showError]="showDocErrors"
      (add)="addDocument($event, i)"
      (remove)="removeDocument(i, $event)"
    >
    </app-upload-card>
  </section>

  <section class="my-4">
    <mat-checkbox formControlName="privacyPolicy" class="d-block">
      Ao preencher o formulário, concordo em receber comunicações de acordo com os meus interesses e aceito os termos da <a href="/politica-de-privacidade" target="_blank">Política de Privacidade</a>.
    </mat-checkbox>

    <mat-checkbox formControlName="bacenAccepted" class="d-block">
      Autorizo a consulta de informações junto a birôs de crédito e ao <a target="_blank" rel="noopener" href="https://s3-sa-east-1.amazonaws.com/antecipagov.public.docs/AUTORIZA%C3%87%C3%83O+PARA+CONSULTA+JUNTO+AO+BACEN.pdf">BACEN</a>.
    </mat-checkbox>
  </section>

  <section class="w-100 p-4 d-flex justify-content-between">
    <button mat-flat-button type="button" (click)="redirectTo('home')">Voltar</button>
    <button
      mat-flat-button
      class="bg-broad p-4"
      type="submit"
    >
      <span *ngIf="!isLoading">Enviar</span>
      <div *ngIf="isLoading" class="d-flex">
        <mat-spinner diameter="20" class="me-2"></mat-spinner>
        <span>Enviando ...</span>
      </div>
    </button>
  </section>
</form>

<app-success-page *ngIf="finished"></app-success-page>
