import { IDocumentList } from "../interfaces/IDocumentList";

export class RegisterDocuments {
  static list(): IDocumentList[] {
    return [
      {
        description: 'Última alteração do contrato social',
        type: 'CONTRATO_SOCIAL',
        required: true,
        multiple: false,
        files: []
      },
      {
        description: 'Documento de identificação dos sócios (CNH ou Identidade)',
        type: 'IDENTIFICACAO',
        required: true,
        multiple: true,
        files: [],
        tooltip: 'Inclua o documento de identificação de todos os sócios (ou representantes legais) da empresa.'
      },
      {
        description: 'Comprovante de residência atualizado dos sócios',
        type: 'ENDERECO',
        required: true,
        multiple: true,
        files: [],
        tooltip: 'Inclua o comprovante de residência atualizado de todos os sócios (ou representantes legais) da empresa.'
      },
    ];

  }
}
