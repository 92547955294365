<main class="container p-4">
  <h1 class="text-center">POLÍTICA DE PRIVACIDADE</h1>
  <p>
    A Broadfactor Sistemas e Apoio Administrativo S.A, inscrita no CNPJ/MF sob o no.
    13.995.981/0001-04, situada na rua João Pessoa, nº 2.033, NewCow Coworking, bairro Costa
    e Silva, Joinville – SC (“Broadfactor”), considera que sua privacidade é muito importante. É
    política da Broadfactor respeitar a sua privacidade em relação a qualquer informação sua
    que possamos coletar em nosso(s) site(s) e plataforma(s). Esta Política de Privacidade
    descreve como protegemos sua privacidade ao tratar dados pessoais coletados pela
    Broadfactor.
  </p>
  <p>
    Sem prejuízo das definições que constam desta Política de Privacidade, as definições dos
    Termos de Uso aplicam-se a esta Política.
  </p>
  <p>
    A Broadfactor oferece um conjunto de serviços através de plataforma(s) de operações de
    crédito seguras para a antecipação de recebíveis. Solicitamos informações apenas quando
    realmente precisamos delas para fornecer um serviço, por meios justos e legais, com o seu
    conhecimento e consentimento, de acordo a Lei no 13.709/18 – Lei Geral de Proteção de
    Dados (LGPD).
  </p>
  <p>
    Para a prestação do conjunto de serviços de antecipação de recebíveis, todas as condições e
    informações relevantes à operação de crédito serão disponibilizadas a compradores,
    cessionários, parceiros de negócio, Sistema de Informações de Crédito (SCR) do Banco
    Central do Brasil, birôs de crédito, Central de Protestos, e fontes de referências.
  </p>
  <p>
    Retemos as informações coletadas apenas pelo tempo necessário para fornecer o serviço
    solicitado. Quando armazenamos dados, protegemos dentro de meios comercialmente
    aceitáveis para evitar perdas e roubos, bem como acesso, divulgação, cópia, uso ou
    modificação não autorizados.
  </p>
  <p>
    Alguns dos terceiros com quem compartilhamos seus dados podem ser localizados ou
    possuir instalações em países estrangeiros. Mesmo assim, seus dados pessoais estarão
    sujeitos à LGPD. A Broadfactor se compromete em adotar eficientes padrões de segurança
    cibernética e de proteção de dados, nos melhores esforços de garantir e cumprir as
    exigências legislativas.
  </p>
  <p>
    Não compartilhamos informações de identificação pessoal publicamente ou com terceiros
    não autorizados, exceto quando exigido por lei.
  </p>
  <p>
    O(s) nosso(s) site(s) e/ou plataforma(s) podem ter links para sites externos que não são
    operados por nós. Esteja ciente de que não temos controle sobre o conteúdo e práticas
    desses sites externos e não podemos aceitar responsabilidade por suas respectivas políticas
    de privacidade.
  </p>
  <p>
    Você é livre para recusar a nossa solicitação de informações pessoais, entendendo que
    talvez não possamos fornecer alguns dos serviços desejados.
  </p>
  <p>
    O uso continuado dos serviços disponibilizados na(s) plataforma(s) da Broadfactor será
    considerado como aceitação de nossas práticas em torno de privacidade e informações
    pessoais. A Broadfactor reserva-se ao direito de alterar essa Política de Privacidade sem
    aviso prévio. Assim, recomenda-se que esta seja consultada com regularidade. Se você ainda
    tiver alguma dúvida sobre como lidamos com dados do usuário e informações pessoais,
    entre em contato conosco.
  </p>
  <h1 class="text-center">POLÍTICA DE COOKIES</h1>
  <h2>O que são cookies?</h2>
  <p>
    O(s) site(s) e/ou plataforma(s) da Broadfactor usam cookies, que são pequenos arquivos
    baixados no seu computador, para melhorar sua experiência. Esta página descreve quais
    informações eles coletam, como as usamos e por que às vezes precisamos armazenar esses
    cookies. Também compartilharemos como você pode impedir que esses cookies sejam
    armazenados, no entanto, isso pode fazer o downgrade ou 'quebrar' certos elementos da
    funcionalidade do(s) nosso(s) site(s) e/ou plataforma(s).
  </p>
  <h2>Como usamos os cookies?</h2>
  <p>
    Utilizamos cookies por vários motivos, detalhados abaixo. Na maioria dos casos, não existem
    opções para desativar os cookies sem desativar completamente a funcionalidade e os
    recursos que eles adicionam. É recomendável que você deixe todos os cookies se não tiver
    certeza se precisa ou não deles, caso sejam usados para fornecer um serviço que você usa.
  </p>
  <h2>Desativar cookies</h2>
  <p>
    Você pode impedir a configuração de cookies ajustando as configurações do seu navegador
    (consulte a Ajuda do navegador para saber como fazer isso). Esteja ciente de que a
    desativação de cookies afetará a funcionalidade e recursos do(s) nosso(s) site(s) e/ou
    plataforma(s) e de muitos outros sites que você visita. Portanto, é recomendável que você
    não os desative.
  </p>
  <h2>Cookies que definimos</h2>
  <p>
    - Cookies relacionados à conta: Se você criar uma conta conosco, usaremos cookies para o
    gerenciamento do processo de cadastramento e administração. Esses cookies geralmente
    serão excluídos quando você sair do sistema, porém, em alguns casos, eles poderão
    permanecer para posteriormente lembrar suas preferências.
  </p>
  <p>
    - Cookies relacionados ao login: Utilizamos cookies quando você está logado, para que
    possamos lembrar dessa ação. Isso evita que você precise fazer login sempre que visitar uma
    nova página. Esses cookies são normalmente removidos ou limpos quando você efetua
    logout para garantir que você possa acessar apenas a recursos e áreas restritas ao efetuar
    login.
  </p>
  <p>
    - Cookies relacionados a boletim informativo: Oferecemos serviços de boletim informativo
    e/ou e-mail e os cookies podem ser usados para lembrar se você já está registrado e se
    devemos mostrar determinadas notificações válidas apenas para usuários inscritos / não
    inscritos.
  </p>
  <p>
    - Cookies relacionados a serviços: Oferecemos serviços e alguns cookies são essenciais para
    garantir que seus pedidos e solicitações sejam lembrados entre as páginas, para que
    possamos processá-lo adequadamente.
  </p>
  <p>
    - Cookies relacionados a pesquisas: Periodicamente fazemos pesquisas e questionários para
    fornecer informações interessantes, ferramentas úteis ou para entender nossa base de
    usuários com mais precisão. Essas pesquisas podem usar cookies para lembrar quem já
    participou numa pesquisa ou para fornecer resultados precisos após a alteração das páginas.
  </p>
  <p>
    - Cookies relacionados a formulários: Quando você envia dados por meio de um formulário,
    os cookies podem ser configurados para lembrar os detalhes do usuário para
    correspondência futura.
  </p>
  <p>
    - Cookies de preferências do site: Para proporcionar uma ótima experiência, você pode
    definir suas preferências de como o site é executado quando você o usa. Para lembrar suas
    preferências, precisamos definir cookies para que essas informações possam ser chamadas
    sempre que você interagir com uma página for afetada por suas preferências.
  </p>

  <h2>Cookies de Terceiros</h2>
  <p>
    Em alguns casos especiais, também usamos cookies fornecidos por terceiros confiáveis.
    Usamos o Google Analytics, que é uma das soluções de análise mais difundidas e confiáveis
    da Web, para nos ajudar a entender como você usa o site e como podemos melhorar sua
    experiência. Esses cookies podem rastrear itens como quanto tempo você gasta no site e as
    páginas visitadas, para que possamos continuar produzindo conteúdo atraente.
  </p>
  <p>
    Quando ainda estamos testando novos recursos, esses cookies podem ser usados para
    garantir que você receba uma experiência consistente enquanto estiver no site, enquanto
    entendemos quais otimizações os nossos usuários mais apreciam.
  </p>
  <p>
    Como vendemos serviços, é importante entendermos as estatísticas sobre quantos
    visitantes de nosso site realmente compram nossos serviços e, portanto, esse é o tipo de
    dados que esses cookies rastrearão.
  </p>
  <p>Para mais informações consulte a página oficial do Google Analytics.</p>
  <p>Novembro/2021.</p>
</main>
