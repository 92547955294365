<footer class="container-fluid p-5 bg-light">
  <div class="row">
    <div class="col-lg-2 d-flex flex-column justify-content-top">
      <img src="assets/svg/logo-broadfactor-colorido.svg" alt="Logo da Broadfactor" class="w-logo mb-4">
    </div>
    <div class="col-2">
      <b>Soluções</b>
      <ul>
        <li><a href="https://broadfactor.com/solucoes/antecipar-recebiveis" target="_blank">Antecipar recebíveis</a></li>
        <li><a href="https://antecipagov.com.br" target="_blank">AntecipaGov</a></li>
      </ul>
    </div>
    <div class="col-lg-2">
      <b>Dúvidas</b>
      <ul>
        <li><a href="https://blogbroadfactor.com/" target="_blank">Blog</a></li>
        <li><a href="https://broadfactors3-public.s3.sa-east-1.amazonaws.com/Termos/cedente/Termos_+de_Uso_Broadfactor.pdf" target="_blank">Termos de Uso</a></li>
        <li><a href="https://broadfactors3-public.s3.sa-east-1.amazonaws.com/Termos/cedente/Pol%C3%ADtica+de+Privacidade+Broadfactor.pdf" target="_blank">Politica de Privacidade</a></li>
      </ul>
    </div>
    <div class="col-lg-2">
      <b>Contato</b>
      <ul>
        <li>contato&#64;broadfactor.com</li>
        <li>(47) 99215-7013</li>
      </ul>
    </div>
    <div class="col-lg-2">
      <b>Endereço</b>
      <ul>
        <li>João Pessoa, nº 2.033, NewCow Coworking, Costa e Silva, Joinville CEP 89.218-533</li>
      </ul>
    </div>
    <div class="col-lg-2">
      <b class="my-2">Redes Sociais</b>
      <div class="d-flex justify-content-left">
        <a href="https://www.instagram.com/broadfactor" target="_blank" class="me-2">
          <img src="assets/svg/instagram-icon.svg" alt="Instagram da Broadfactor" class="w-20">
        </a>
        <a href="https://www.linkedin.com/company/broadfactor" target="_blank">
          <img src="assets/svg/linkedin-icon.svg" alt="Linkedin da Broadfactor" class="w-20">
        </a>
      </div>
    </div>
  </div>
  <div class="d-flex flex-column align-items-center mt-3 body-small-bold footer-info">
    <span>© 2024 BROADFACTOR - 13.995.981/0001-04</span>
    <span>Joinville - SC, 89.218-533</span>
  </div>
</footer>
