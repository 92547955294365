<div class="content min-vh-100 p-4">
  <section>
    <h1 class="light-dark-color">Cadastro Realizado com Sucesso!</h1>
    <p>
      Seu cadastro foi finalizado e estamos analisando os dados fornecidos. Em breve, você receberá um e-mail com mais informações e os próximos passos.
    </p>
    <p>Em caso de dúvidas consulte nossa seção de perguntas frequentes <a href="/perguntas-frequentes" target="_blank"><b class="text-muted"> (FAQ)</b></a>.</p>
  </section>
  <img src="assets/svg/waiting.svg" alt="Imagem de Representando Sucesso" class="w-25">
</div>
