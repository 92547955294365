<main class="container-fluid min-vh-100 mb-5">
  <section class="d-flex flex-column align-items-center border bg-light-dark">
    <h1 class="light-dark-color">Precisa de Ajuda?</h1>
    <p class="subtitle">
      Queremos que o seu negócio cresça cada vez mais. Sempre que precisar de ajuda entre em contato através dos nossos canais de atendimento.
      <b>Segunda à sexta, das 8h às 12h, e das 13h às 17h.</b>
    </p>

    <button
      onclick="window.open('https://api.whatsapp.com/send/?phone=5547992157013&text&app_absent=1')"
      mat-stroked-button class="my-2 p-4"
      class="bg-white p-4"
    >
      <img class="icon" src="assets/svg/whatsapp.svg" alt="Ícone do WhatsApp.">
      <span>Whatsapp</span>
    </button>
  </section>
  <section class="row">
    <section class="col-md-6">
      <mat-card appearance="outlined">
        <mat-card-content class="d-flex align-items-center">
          <mat-icon class="me-4">mail</mat-icon>
          <span>
            <b class="d-block">Podemos te ajudar por email</b>
            <span class="d-block">suporte&#64;broadfactor.com</span>
          </span>
        </mat-card-content>
      </mat-card>
      <mat-card appearance="outlined">
        <mat-card-content class="d-flex align-items-center">
          <mat-icon class="me-4">call</mat-icon>
          <span>
            <b class="d-block">Contato Broadfactor.</b>
            <span class="d-block">(47) 99215-7013</span>
          </span>
        </mat-card-content>
      </mat-card>
      <mat-card appearance="outlined">
        <mat-card-content class="d-flex align-items-center">
          <mat-icon class="me-4">location_on</mat-icon>
          <span>
            <b class="d-block">Localização</b>
            <span class="d-block">Rua Jerônimo Coelho, nº 78, CEP: 89201-050. Joinville (SC)</span>
          </span>
        </mat-card-content>
      </mat-card>
      <mat-card appearance="outlined">
        <mat-card-content class="d-flex align-items-center">
          <mat-icon class="me-4">quiz</mat-icon>
          <span>
            <b class="d-block">Consulte nossa FAQ com perguntas frequentes</b>
            <span class="d-block"><a  href="/perguntas-frequentes" target="_blank">FAQ</a></span>
          </span>
        </mat-card-content>
      </mat-card>
      <mat-card appearance="outlined">
        <mat-card-content class="d-flex align-items-center">
          <mat-icon class="me-4">web</mat-icon>
          <span>
            <b class="d-block">Consulte nosso BLOG explore artigos financeiros</b>
            <span class="d-block"><a href="https://blogbroadfactor.com/" target="_blank">BLOG</a></span>
          </span>
        </mat-card-content>
      </mat-card>

    </section>
    <figure class="col-md-6 mb-0 d-flex align-items-center justify-content-center">
      <img
        class="img-contact-us" width="900" height="500" priority
        ngSrc="assets/svg/landing-page/help-center/contact-us.svg"
        alt="Ilustração de uma pessoa segurando uma carta, com ícones de celular e telefone ao redor, representando a central de ajuda e informações de contato."
      >
    </figure>
  </section>
</main>
