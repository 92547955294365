<mat-toolbar class="d-flex justify-content-between align-items-center px-5">
  <img class="w-logo" (click)="redirectTo('home')" src="assets/svg/logo-broadfactor-colorido.svg" alt="Logo da Broadfactor" data-cy="header-broadfactor-logo">
  <ul class="d-none d-md-flex justify-content-between align-items-center mb-0">
    <li (click)="redirectTo('home')" data-cy="header-redirect-to-home-link">Início </li>
    <li>
      <a href="https://broadfactor.com/broadfactor/sobre" target="_blank" data-cy="header-about-us-link">Sobre</a>
    </li>
    <li (click)="redirectTo('solucoes')" data-cy="header-solutions-link">Soluções</li>
    <li href (click)="redirectTo('login')" data-cy="header-login-link">
      <a href="https://broadfactorpay.dbs.moneyp.com.br/login" target="_blank">Login</a>
    </li>
    <button mat-flat-button class="bg-broad" (click)="onRegister()" data-cy="header-create-account-link">Crie sua conta</button>
  </ul>
  <button mat-icon-button aria-label="Botão de Menu" (click)="onClick()" class="d-block d-md-none">
    <mat-icon>menu</mat-icon>
  </button>
</mat-toolbar>
