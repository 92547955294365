import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { IRegisterForms } from '../../pages/register/interfaces/IRegisterForms';
import { IDocumentList } from '../../pages/register/interfaces/IDocumentList';
import { IInitialRegister } from '../../pages/register/interfaces/IInitialRegister';
import { IPartnerRegister } from '../../pages/register/interfaces/IPartnerRegister';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {
  private companyId: string = '';
  private partnerId: string = '';

  constructor(private http: HttpClient) {}

  addInitialRegister(data: IInitialRegister){
    return this.http.post<string>(environment.register_api + 'open/register/broadfactor_banking/initial', data);
  }

  addCompleteRegister(data: IRegisterForms){
    return this.http.post<string>(environment.register_api + 'open/register/broadfactor_banking', data);
  }

  addDocuments(files:any[], type: string, isCompanyDocument: boolean){
    return (isCompanyDocument) ? this.addCompaynDocuments(files, type) : this.addPartnerDocuments(files, type);
  }


  addCompaynDocuments(files:any[], type: string){
    const formData = new FormData();
    files.forEach(file => {
      formData.append('files', file, file.name);
    });

    formData.append('type', type);


    return this.http.post(environment.register_api + 'open/register/document/' + this.companyId + '/all/', formData);
  }

  addPartnerDocuments(files:any[], type: string){
    const formData = new FormData();
    files.forEach(file => {
      formData.append('files', file, file.name);
    });

    formData.append('type', type);


    return this.http.post(
      environment.register_api +
      'open/register/document/' + this.companyId +
      '/partner/'+ this.partnerId + '/all/', formData
    );
  }

  addPartner(partner: IPartnerRegister) {
    return this.http.post<string>(
      environment.register_api +
      'open/register/broadfactor_banking/' + this.companyId +'/partner', partner
    );
  }

  getCompanyId() {
    return this.companyId;
  }

  setCompanyId(companyId: string) {
    this.companyId = companyId;
  }

  getPartnerId() {
    return this.partnerId;
  }

  setPartnerId(partnerId: string) {
    this.partnerId = partnerId;
  }
}
