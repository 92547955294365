import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-home',
    imports: [MatToolbarModule, MatButtonModule],
    templateUrl: './home.component.html',
    styleUrl: './home.component.scss'
})
export class HomeComponent {
  source: string = '';
  constructor(private router: Router, private route: ActivatedRoute) {
    this.source = this.route.snapshot.data['source'];
  }

  redirectToRegister(){
   const path = (this.source === 'B2gContabilidade') ? '/b2g-contabilidade/register' : '/register';

    this.router.navigate([path]);
  }
}
