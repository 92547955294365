<mat-toolbar class="d-flex justify-content-between align-items-center bg-light px-5">
  <img class="w-logo" src="assets/svg/logo-broadfactor-colorido.svg" alt="Logo da Broadfactor">
  <ul class="d-none d-md-flex justify-content-between align-items-center mb-0">
    <li (click)="goToSection('start')">Início </li>
    <li (click)="goToSection('about-us')">Quem somos</li>
    <li (click)="goToSection('solutions')">Soluções</li>
    <button mat-flat-button class="bg-broad" (click)="onRegister()">Crie sua Conta</button>
  </ul>
  <button mat-icon-button aria-label="Botão de Menu" (click)="onClick()" class="d-block d-md-none">
    <mat-icon>menu</mat-icon>
  </button>
</mat-toolbar>
