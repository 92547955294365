import { NgIf } from '@angular/common';
import { ICard } from '../../interfaces/ICard';
import { AppMaterialModule } from './../../../../shared/app-material/app-material.module';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-card',
  standalone: true,
  imports: [AppMaterialModule, NgIf],
  templateUrl: './card.component.html',
  styleUrl: './card.component.scss'
})
export class CardComponent {
  @Input() data!: ICard;
}
