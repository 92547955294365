<!-- Início -->
<app-home id="start"></app-home>

<section class="container-fluid d-flex align-items-center  bg-light">
  <div class="row">
    <img class="col-6"  src="assets/svg/landing-page/phone-with-girl.svg" alt="" >
    <div class="col d-flex flex-column justify-content-center">
      <h1 class="mb-4" >Transforme a gestão financeira da sua empresa</h1>
      <p class="text-justify">
        Você simplifica o controle de pagamentos, antecipa recebíveis para manter o fluxo de caixa sempre saudável, elimina a burocracia e ainda ganha tempo para focar no que realmente importa.O crescimento do seu negócio.
      </p>
    </div>
  </div>
</section>

<!-- Somos a Broadfactor Pay -->
<section class="container-fluid d-flex flex-column align-items-center justify-content-center" id="solutions">
  <div class="row">
    <div class="col">
      <h1 class="mb-4 color-broad-pay">Broadfactor Pay</h1>
      <p class="text-justify w-100">
        Você tem uma conta digital PJ completa para simplificar a gestão financeira do seu negócio.  Antecipe seus recebíveis,como recebíveis do governo e outros, mantenha o fluxo de caixa sempre em dia e conte com a  segurança de uma solução confiável. Trabalhamos também com antecipações, garantindo que você tenha capital disponível  quando precisar.
      </p>
    </div>
    <div class="row">
      <div class="col d-flex flex-column justify-content-center">
        <h1 class="mb-4 color-broad-pay">Somos Autorizados</h1>
        <p class="text-justify w-75">
          E o melhor: somos autorizados e regulados pelo Banco Central, código bancário xxx. Broadfactor Pay.
        </p>
      </div>
      <img class="col-2"  src="assets/svg/landing-page/person-handling-money.svg" alt="" >
    </div>
  </div>
</section>

<!-- Instruções -->
<section class="container-fluid bg-light d-flex flex-column justify-content-center">
  <div class="row">
    <h1 class="col text-center mb-5">Abra sua conta digital PJ na Broadfactor </h1>
  </div>
  <div class="row d-flex justify-content-center align-items-center">
    <img class="d-none d-md-block col-3  mx-5" src="assets/svg/landing-page/letra-b.svg" alt="">
    <div class="col-md-5 d-flex flex-column">
      <app-card *ngFor="let item of data" [data]="item"></app-card>
    </div>
  </div>
</section>

<!-- Somos a Broadfactor -->
<section class="container-fluid d-flex align-items-center " id="about-us">
  <div class="row">
    <div class="col d-flex flex-column justify-content-center">
      <h1 class="mb-4 color-broad-pay">Somos a Broadfactor!</h1>
      <p class="text-justify">
        A primeira plataforma digital de antecipação de recebíveis do mundo, criada com o propósito de levar prosperidade aos empreendedores. Nossas soluções incluem a antecipação de recebíveis, compra de recebíveis e o Antecipagov — tudo para garantir que sua empresa tenha o fluxo de caixa necessário para crescer, sem complicações.
      </p>
    </div>
    <img class="col-4"  src="assets/svg/landing-page/person-look-phone.svg" alt="" >
  </div>
</section>
<section class="container-fluid d-flex align-items-center bg-light">
  <div class="row">
    <img class="col-4"  src="assets/svg/landing-page/person-with-lamp.svg" alt="" >
    <div class="col d-flex flex-column justify-content-center">
      <h1 class="mb-4 color-broad-pay">Precisa de ajuda?</h1>
      <p class="text-justify">
        Você pode entrar em contato através do nosso whatsapp, e-mail ou através dos telefones de contato, de segunda à sexta, das 8h às 12h, e das 13h às 17h. Ficaremos muito felizes em poder ajudá-lo!
      </p>
      <a href="https://api.whatsapp.com/send/?phone=5547992157013&text&app_absent=1" target="_blank"><button mat-raised-button class="bg-white col-md-3">Entrar em contato</button></a>
    </div>
  </div>
</section>
