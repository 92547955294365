<div class="container-fluid row d-flex justify-content-center  justify-content-lg-start align-items-center">
  <img
    class="col-md-4 col-sm-6 my-4"
    width="388"
    height="380"
    ngSrc="assets/svg/landing-page/home/complete-solution-section.svg"
    alt="Um homem segurando um celular e um cartão de crédito"
    loading="lazy"
    data-cy="home-complete-solution-img"
  >
  <div class="col-lg-7 col-md-12 col-sm-12">
    <h1 class="light-dark-color" data-cy="home-complete-solution-title">
      Uma <span class="second-color">solução financeira</span> completa com<span class="second-color"> crédito disponível</span> para seu negócio
    </h1>
    <p class=" my-4 fs-5" data-cy="home-complete-solution-description">
      <b class="text-muted">O crédito que você precisa</b>, integrado a uma solução financeira que economiza tempo e fortalece o crescimento do seu negócio.
    </p>
  </div>
</div>
