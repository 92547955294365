import { Component } from '@angular/core';
import { AppMaterialModule } from '../../../../shared/app-material/app-material.module';
import { HeaderComponent } from '../header/header.component';
import { FooterComponent } from '../../../../core/shell/footer/footer.component';
import { Router, RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-layout',
  standalone: true,
  imports: [AppMaterialModule, HeaderComponent, FooterComponent, RouterOutlet],
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.scss'
})
export class LayoutComponent {

  constructor(private router: Router) { }

  goToSection(sectionID: string){
    document.getElementById(sectionID)?.scrollIntoView({behavior: 'smooth'});
  }

  redirectTo(path: string){
    this.router.navigate([path]);
  }
}
