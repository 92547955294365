<footer class="container-fluid p-5 bg-second-dark ">
  <div class="row">
    <div class="col-lg-3 d-flex flex-column justify-content-top">
      <img src="assets/svg/logo-broadfactor-colorido-branco.svg" alt="Logo da Broadfactor" class="w-logo mb-4"
        data-cy="footer-broadfactor-logo"
        >
    </div>
    <div class="col-lg-2">
      <b>Broadfactor</b>
      <ul>
        <li><a href="https://broadfactor.com/broadfactor/sobre" target="_blank" data-cy="footer-about-us-link">Sobre nós</a></li>
        <li><a href="https://blogbroadfactor.com/" target="_blank" data-cy="footer-blog-link">Blog</a></li>
      </ul>
    </div>
    <div class="col-lg-2">
      <b>Ajuda</b>
      <ul>
        <li><a [href]="helpCenterURL" target="_blank" data-cy="footer-help-link">Central de Ajuda</a></li>
        <li><a href="https://broadfactors3-public.s3.sa-east-1.amazonaws.com/Termos/cedente/Termos_+de_Uso_Broadfactor.pdf" target="_blank" data-cy="footer-use-terms-link">Termos de Uso</a></li>
        <li><a [href]="privacyPolicyURL" target="_blank" data-cy="footer-privacy-policy-link">Política de Privacidade (LGPD)</a></li>
      </ul>
    </div>
    <div class="col-lg-3">
      <b>Canais de atendimento</b>
      <ul>
        <li data-cy="footer-help-email">suporte&#64;broadfactor.com</li>
        <li data-cy="footer-help-phone">(47) 99215-7013</li>
      </ul>
    </div>

    <div class="col-lg-2 mb-4">
      <b class="my-2">Redes Sociais</b>
      <div class="d-flex justify-content-left">
        <a href="https://www.instagram.com/broadfactoroficial" target="_blank" data-cy="footer-instagram-link">
          <img height="30" src="assets/svg/instagram-icon.svg" alt="Ícone que redireciona para o instagram da Broadfactor" class="w-20">
        </a>
        <a  class="mx-2" href="https://www.linkedin.com/company/broadfactor" target="_blank" data-cy="footer-linkedin-link">
          <img height="30" src="assets/svg/linkedin-icon.svg" alt="Ícone que redireciona para o linkedin da Broadfactor" class="w-20">
        </a>
        <a href="https://www.youtube.com/channel/UChxZcuH2MjPLA7ebO4VKqUA" target="_blank" data-cy="footer-youtube-link">
          <img height="30" src="assets/svg/youtube-icon.svg" alt="Ícone que redireciona para o youtube da Broadfactor" class="w-20">
        </a>
        <a class="mx-2" href="https://www.facebook.com/broadfactor" target="_blank" data-cy="footer-facebook-link">
          <img src="assets/svg/facebook-icon.svg" alt="Ícone que redireciona para o facebook da Broadfactor" class="w-20">
        </a>
      </div>
    </div>

  </div>

  <section class="row">
    <figure class="col-lg-10 d-flex flex-column">
      <a href="https://play.google.com/store/apps/details?id=br.com.broadfactorpay" target="_blank" class="store" data-cy="footer-google-play-link">
        <img class="store" src="assets/svg/google-play.svg" alt="Imagem do Google Play" data-cy="footer-google-play-img">
      </a>
      <a href="https://apps.apple.com/br/app/broadfactorpay/id6739220639" target="_blank" class="store" data-cy="footer-apple-store-link">
        <img class="store" src="assets/svg/apple-store.svg" alt="Imagem da Apple Store" data-cy="footer-apple-store-img">
      </a>
    </figure>
    <figure class="col-lg-2">
      <img src="assets/svg/fintech.svg" alt="Certificado Fintech Segura Nível 1" data-cy="footer-fintech-certified-img">
    </figure>
  </section>
  <div class="d-flex flex-column align-items-center mt-3 body-small-bold footer-info">
    Copyright 2024 Broadfactor Sistema e Apoio Administrativo CNPJ: 13.995.981/0001-04 | Todos os direitos reservados
  </div>
</footer>
