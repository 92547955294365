import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { IRegisterForms } from '../../pages/register/interfaces/IRegisterForms';
import { IDocumentList } from '../../pages/register/interfaces/IDocumentList';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  constructor(private http: HttpClient) {}

  addInitialRegister(data: IRegisterForms){
    return this.http.post(environment.register_api + 'open/register/broadfactor_banking/init', data);
  }

  addCompleteRegister(data: IRegisterForms){
    return this.http.post<string>(environment.register_api + 'open/register/broadfactor_banking', data);
  }

  addDocuments(files:any[], type: string, companyId: string){
    const formData = new FormData();
    files.forEach(file => {
      formData.append('files', file, file.name);
    });
  
    formData.append('type', type);

    return this.http.post(environment.register_api + 'secure-api/company/' + companyId + '/document/all/', formData);
  }
}
