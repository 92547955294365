import { Routes } from '@angular/router';

import { HomeComponent } from './pages/home/home.component';
import { RegisterFormsEventComponent } from './pages/register/shell/register-forms-event/register-forms-event.component';
import { RegisterFormsComponent } from './pages/register/shell/register-forms/register-forms.component';
import { LandingPageComponent } from './pages/landing-page/shell/landing-page/landing-page.component';
import { DefaultLayoutComponent } from './core/shell/default-layout/default-layout.component';
import { LayoutComponent } from './pages/landing-page/shell/layout/layout.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';

export function routes(): Routes {
  const hostname = window.location.hostname;
  const subdomain = hostname.split('.')[0];

  switch(subdomain) {
    case 'b2g':
      return [
        {
          path: '',
          component: DefaultLayoutComponent,
          children: [
            {path: 'home', component: HomeComponent},
            {path: 'register', component: RegisterFormsComponent},
            {path: 'politica-de-privacidade', component: PrivacyPolicyComponent},

            {path: '', redirectTo: '/home', pathMatch: 'full' },
            {path: 'evento', redirectTo: '/register', pathMatch: 'full' },
            {path: '**', redirectTo: '/home' ,pathMatch: 'full'}
          ]
        },
      ];
    default:
      return [
        {
          path: 'home',
          component: LayoutComponent,
          children: [
            {path: '', component: LandingPageComponent},
          ]
        },
        {
          path: 'register',
          component: DefaultLayoutComponent,
          children: [
            {path: '', component: RegisterFormsComponent},
          ]
        },
        {
          path: 'politica-de-privacidade',
          component: DefaultLayoutComponent,
          children: [
            { path: '', component: PrivacyPolicyComponent },
          ]
        },

        {path: '', redirectTo: '/home', pathMatch: 'full' },
        {path: '**', redirectTo: '/home' ,pathMatch: 'full'}
      ];
  }
}
