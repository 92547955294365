<mat-card
  appearance="outlined"
  class="mb-2"
  [ngClass]="{'error': showError && !item.files.length}"
>
  <mat-card-content class="d-flex align-items-center">
    <button
      mat-icon-button
      class="me-2" type="button"
      (click)="fileInput.click()"
    >
      <mat-icon >file_upload</mat-icon>
      <input
        class="d-none" type="file"
        #fileInput
        accept="'.png', '.PNG', '.jpg', '.JPG', '.jpeg', '.JPEG', '.pdf', '.PDF'"
        (change)="addDocument($event)"
        [multiple]="item.multiple"
      >
    </button>
    <div class="d-flex flex-column">
      <span class="d-flex ">
        {{ item.description }}
        <small *ngIf="item.required">*</small>
        <mat-icon class="ms-2 text-muted" *ngIf="!!item.tooltip" [matTooltip]="item.tooltip">help</mat-icon>
      </span>
      <small class="d-flex" *ngFor="let file of item.files; let i = index">
        <mat-icon color="warn" style="cursor: pointer;" (click)="removeDocument(i)">
          remove
        </mat-icon>
        {{ file.name }}
      </small>
    </div>
  </mat-card-content>
</mat-card>
